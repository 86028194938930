
interface IRequestConfig {
  method: "get" | "head" | "patch" | "post" | "put" | "delete" | "connect" | "options" | "trace";
  url: string;
  body?: any;
}

export type ResponseType<T> = ReturnType<typeof useBackendFetch<T>>;

export function doRequest<T>(config: IRequestConfig): ResponseType<T> {
  const result = useBackendFetch<T>(config.url, {
    method: config.method,
    body: config.body,
  });
  return result;
}
